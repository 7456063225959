import React from "react"

const Info = () => {
  return (
    <section>
      <h2>Tarieven acupunctuurpraktijk Quan Yin</h2>
      <table id="pricelist">
        <thead>
          <tr>
            <th>Omschrijving</th>
            <th>Prijs</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Normaal consult</td>
            <td>€70,-</td>
          </tr>
          <tr>
            <td>Normaal consult studenten</td>
            <td>€52,50</td>
          </tr>
          <tr>
            <td>Kort consult</td>
            <td>€45,-</td>
          </tr>
          <tr>
            <td>Consult kinderen tot 18 jaar</td>
            <td>€45,-</td>
          </tr>
        </tbody>
      </table>

      <p>
        Afspraken kunnen zonder probleem tot 24u van tevoren verzet of afgezegd
        worden. Indien u onverwacht niet op komt dagen zonder geldige reden zal
        het consult in rekening gebracht worden.
      </p>

      <p>
        Ik ben lid van de beroepsvereniging <a href="http://zhong.nl/">ZHONG</a>{" "}
        dus de meeste zorgverzekeraars vergoeden de acupunctuurconsulten of een
        deel daarvan mits u een aanvullend pakket heeft. Een verwijsbriefje van
        de huisarts is meestal niet nodig.
      </p>

      <h2>Over acupunctuurpraktijk Quan Yin</h2>

      <div className="well">
        Quan Yin: godin van mededogen, krijgsvrouw, godin met de duizend
        handjes.
      </div>

      <h2>De praktijk</h2>

      <p>
        Acupunctuurpraktijk Quan Yin is op dit moment gehuisvest in{" "}
        <a href="http://www.cig-amsterdam.nl">Centrum Integrale Geneeskunde</a>{" "}
        aan de Sarphatikade 22. In deze praktijk zijn meerdere disciplines
        verenigd waardoor uitwisseling van kennis makkelijk en gebruikelijk is.
        Op dinsdag behandel ik van 14:00u - 19:00u in{" "}
        <a href="http://www.fysiotherapiezeeburgereiland.nl">
          Fysiotherapiepraktijk Zeeburgereiland
        </a>
        , Kea Boumanstraat 114. Hier is ook samenwerking met een verloskundige
        praktijk mogelijk.
      </p>
    </section>
  )
}

export default Info
