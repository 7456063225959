import React, { Fragment, useState } from "react"
import { IconButton, makeStyles, Drawer } from "@material-ui/core"
import { Menu } from "@material-ui/icons"
import { NavLink } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  iconButton: {
    position: "absolute",
    top: 115,
    right: 20,
  },
  navList: {
    textAlign: "center",
    listStyle: "none",
    margin: 0,
    borderTop: `1px solid ${theme.palette.primary.dark}`,
    padding: 0,
    overflow: "hidden",
  },
  navLink: {
    color: "white",
    textDecoration: "none",
    "&:hover": {
      color: "white",
    },
  },
  navItem: {
    padding: 10,
    backgroundColor: theme.palette.primary.light,
    borderBottom: `1px solid lightGrey`,
  },
}))

const MobileNav = ({ menuItems, setSubTitle }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleClose = () => setOpen(false)
  return (
    <Fragment>
      <IconButton
        onClick={() => setOpen(!open)}
        className={classes.iconButton}
        color="primary"
      >
        <Menu style={{ fontSize: 40 }} />
      </IconButton>
      <Drawer onClose={handleClose} anchor="bottom" open={open}>
        <ul className={classes.navList}>
          {menuItems.map((i) => (
            <NavLink
              key={i.path}
              className={classes.navLink}
              onClick={() => handleClose()}
              to={i.path}
            >
              <li className={classes.navItem}>{i.label}</li>
            </NavLink>
          ))}
        </ul>
      </Drawer>
    </Fragment>
  )
}

export default MobileNav
