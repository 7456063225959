import React from "react"
import { makeStyles } from "@material-ui/core"
import china from "../images/pictures/home.jpg"

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
  },
}))

const Home = () => {
  const classes = useStyles()

  return (
    <section>
      <h1>Acupunctuur in Amsterdam</h1>

      <img className={classes.image} src={china} alt="china acupunctuur" />

      <div className="well">Een eeuwenoude geneeskunde springlevend.</div>

      <p>
        Steeds meer mensen zijn op zoek naar een aanvulling op onze westerse
        geneeswijze. Verbeteren van de weerstand, verlichten van pijn,
        verminderen van allergie, ondersteuning bij chemo of radiotherapie, hulp
        bij fertiliteitsproblemen, slapeloosheid of onrust. Acupunctuur, kruiden
        en supplementen blijken aan die behoefte te voldoen, mits zorgvuldig
        toegepast.
      </p>

      <h2>Acupunctuur en de WHO</h2>

      <p>
        Acupunctuur is verreweg de meest bekende TCG discipline in Europa. Deze
        bekendheid is ontstaan door de goede resultaten bij pijnbestrijding. Met
        acupunctuur kunnen echter nog veel meer aandoeningen behandeld worden.
        De wereldgezondheidsorganisatie (WHO) heeft een lijst gepubliceerd van
        ziektes, waarvan vast staat dat zij door middel van acupunctuur te
        genezen, c.q. te verbeteren zijn. Enkele daarvan zijn:
      </p>

      <ul>
        <li>ademhalingsklachten</li>
        <li>allergie</li>
        <li>
          arthritis en arthrose
          <br />
        </li>
        <li>depressies</li>
        <li>angsten</li>
        <li>onrusten</li>
        <li>fertiliteitsproblemen</li>
        <li>hartklachten en hoge bloeddruk</li>
        <li>hernia, lage rugklachten</li>
        <li>herpes zoster</li>
        <li>hoofdpijnen</li>
        <li>hormonale klachten</li>
        <li>huidklachten</li>
        <li>hyperventilatie</li>
        <li>maag- en darmklachten</li>
        <li>menstruatieproblemen</li>
        <li>ontstekingen</li>
        <li>slapeloosheid</li>
        <li>trigeminus neuralgie (aangezichtspijnen)</li>
        <li>verslavingen</li>
      </ul>
      <p>
        <i>bron: Wereldgezondheidsorganisatie</i>
      </p>
    </section>
  )
}

export default Home
