import React from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  navItems: {
    boxSizing: "border-box",
    textDecoration: "none",
    color: theme.palette.primary.main,
    flexGrow: 1,
    wordWrap: "normal",
    border: "none",
    textAlign: "center",
    padding: "10px 0",
    "&:hover": {
      color: theme.palette.secondary.main,
      borderTop: `2px solid ${theme.palette.secondary.main}`,
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
      padding: "5px 0",
    },
  },
  active: {
    color: theme.palette.secondary.main,
    borderTop: `2px solid ${theme.palette.secondary.main}`,
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    padding: "5px 0",
  },
}));

const AnimatedNavLink = ({ i, subTitle }) => {
  const classes = useStyles();

  return (
    <li key={i.path}>
      <NavLink
        className={clsx(
          classes.navItems,
          i.label === subTitle ? classes.active : ""
        )}
        to={i.path}
      >
        {i.label}
      </NavLink>
    </li>
  );
};

export default AnimatedNavLink;
