import React from "react"
import { TextField, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  input: {
    background: "white",
  },
}))

const Input = (props) => {
  const { label, value, onChange } = props
  const classes = useStyles()

  return (
    <TextField
      error={Boolean(props.error)}
      helperText={props.error}
      margin="dense"
      className={classes.input}
      fullWidth
      label={label}
      value={value}
      onChange={onChange}
      variant="outlined"
      multiline={props.multiline}
      rows={props.rows}
    />
  )
}

export default Input
