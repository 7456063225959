import validate from "./validate"
import Joi from "joi-browser"

const schema = {
  name: Joi.string()
    .max(500)
    .required()
    .error(() => {
      return { message: "Vul uw naam in" }
    }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .error(() => {
      return { message: "Vul een juist e-mailadres in" }
    }),
  subject: Joi.string()
    .max(500)
    .required()
    .error(() => {
      return { message: "Vul een onderwerp in" }
    }),
  message: Joi.string()
    .max(2000)
    .required()
    .error(() => {
      return { message: "Bericht is verplicht (maximaal 1000 tekens)" }
    }),
}

export default (input) => validate(input, schema)
