import React from "react"

const NotFound = () => {
  return (
    <h4 style={{ textAlign: "center" }}>
      404 - De pagina die zoekt bestaat niet!
    </h4>
  )
}

export default NotFound
