import React from "react"
import { Switch, Route } from "react-router-dom"
import Home from "./components/Home"
import Acupunctuur from "./components/Acupunctuur"
import Navbar from "./components/Navbar"
import { Container, makeStyles } from "@material-ui/core"
import Kruiden from "./components/Kruiden"
import Info from "./components/Info"
import About from "./components/About"
import Contact from "./components/Contact"
import TopBar from "./components/TopBar"
import BottomBar from "./components/BottomBar"
import NotFound from "./components/NotFound"

const useStyles = makeStyles((theme) => ({
  wrapper: {
    color: theme.palette.primary.main,
    background: "#f2f2f2",
    padding: 20,
    marginBottom: 20,
    borderTop: `solid 1px ${theme.palette.primary.main}`,
    marginTop: 40,
  },
  container: {
    minHeight: "calc(100% - 116px)",
  },
}))

function App() {
  const classes = useStyles()

  return (
    <div className="app">
      <TopBar />
      <Container className={classes.container}>
        <Navbar />
        <div className={classes.wrapper}>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/acupunctuur" component={Acupunctuur} />
            <Route path="/kruiden" component={Kruiden} />
            <Route path="/tarieven-&-info" component={Info} />
            <Route path="/over-barbara" component={About} />
            <Route path="/contact" exact component={Contact} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </Container>
      <BottomBar />
    </div>
  )
}

export default App
