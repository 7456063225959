import React from "react"
import china from "../images/pictures/china_081.jpg"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  image: { width: "100%" },
}))

const Kruiden = () => {
  const classes = useStyles()
  return (
    <section>
      <h2>Kruiden</h2>

      <p>
        Als aanvulling op acupunctuur kunnen kruiden nuttig zijn. Er wordt
        gebruik gemaakt van eeuwenoude en beproefde formules en de laatste jaren
        zijn veel formules ontwikkeld die goed aansluiten bij moderne medicijnen
        en deze ook ondersteunen. Zo helpen we mensen bv hun chemo- of
        radiotherapie beter te verdragen, hun conditie te verbeteren waardoor ze
        langer met hun behandelingen door kunnen gaan (en de bijwerkingen minder
        zijn). De Chinese kruidenleer maakt gebruik van veel wortels, bast,
        bladeren, bloemen en paddestoelen die hier ook voorkomen. Ik gebruik
        alleen zeer streng gecontroleerde kruiden en een enkele keer mineralen.
      </p>

      <img className={classes.image} src={china} alt="kruiden china" />
    </section>
  )
}

export default Kruiden
