import React from "react"
import home from "../images/banners/bottom_bar_home.jpg"
import info from "../images/banners/bottom_bar_de_praktijk.jpg"
import kruiden from "../images/banners/bottom_bar_actueel.jpg"
import about from "../images/banners/bottom_bar_over_barbara.jpg"
import acupunctuur from "../images/banners/bottom_bar_prijzen.jpg"
import { useLocation } from "react-router-dom"

const getBackground = (pathname) => {
  switch (pathname) {
    case "/":
      return home
    case "/acupunctuur":
      return acupunctuur
    case "/kruiden":
      return kruiden
    case "/tarieven-&-info":
      return info
    case "/over-barbara":
      return about
    default:
      return home
  }
}

const BottomBar = () => {
  const { pathname } = useLocation()

  return (
    <div
      style={{
        backgroundImage: `url(${getBackground(pathname)})`,
        width: "100%",
        margin: 0,
        height: 38,
        flexShrink: 0,
      }}
    />
  )
}

export default BottomBar
