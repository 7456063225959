import React from "react";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import AnimatedNavLink from "./AnimatedLink";
import MobileNav from "./MobileNav";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  title: {
    fontFamily: "EB Garamond, serif",
    color: theme.palette.primary.main,
    fontSize: 50,
    letterSpacing: 3,
    whiteSpace: "nowrap",
  },
  subTitle: {
    float: "right",
    marginTop: 55,
    marginRight: 20,
    fontFamily: "Alex Brush, cursive",
    color: theme.palette.secondary.main,
    letterSpacing: 3,
    fontSize: 30,
    whiteSpace: "nowrap",
    "@media (max-width: 830px)": {
      marginTop: 120,
      marginLeft: -213,
    },
  },
  nav: {
    margin: "auto",
    listStyle: "none",
    display: "flex",
    justifyContent: "space-between",
    maxWidth: 800,
    padding: 20,
    whiteSpace: "nowrap",
  },
}));

const menuItems = [
  { label: "Home", path: "/" },
  { label: "Acupunctuur", path: "/acupunctuur" },
  { label: "Kruiden", path: "/kruiden" },
  { label: "Tarieven & Info", path: "/tarieven-&-info" },
  { label: "Over Barbara", path: "/over-barbara" },
  { label: "Contact", path: "/contact" },
];

const Navbar = () => {
  const classes = useStyles();
  const mobileMenu = useMediaQuery("(max-width:830px)");
  const { pathname } = useLocation();
  const menuItem = menuItems.find(({ path }) => pathname === path);
  const subTitle = menuItem ? menuItem.label : "Niet Gevonden!";

  return (
    <div className={classes.root}>
      <header>
        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: 1 }}>
            <h1 className={classes.title}>Quan Yin</h1>
          </div>
          <div
            style={{
              display: "flex",
              justifyItems: "flex-end",
            }}
          >
            <h2 className={classes.subTitle}>{subTitle}</h2>
          </div>
        </div>
      </header>
      {mobileMenu ? (
        <MobileNav menuItems={menuItems} />
      ) : (
        <nav className={classes.navRoot}>
          <ul className={classes.nav}>
            {menuItems.map((i) => (
              <AnimatedNavLink key={i.path} i={i} subTitle={subTitle} />
            ))}
          </ul>
        </nav>
      )}
    </div>
  );
};

export default Navbar;
