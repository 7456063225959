import React from "react"
import { Button, CircularProgress, makeStyles } from "@material-ui/core"
import PropTypes from "prop-types"
import clsx from "clsx"

const useStyles = makeStyles((theme) => ({
  spinner: { marginRight: theme.spacing(2) },
  loadingText: { color: theme.palette.primary.main },
  topMargin: { marginTop: theme.spacing(1) },
}))

const LoadingButton = (props) => {
  const classes = useStyles()
  const topMargin = props.withTopMargin ? classes.topMargin : null

  return (
    <Button
      onClick={props.onClick}
      className={clsx(props.className, topMargin)}
      disabled={props.loading}
      variant={props.variant}
      size={props.size}
      color={props.color}
      fullWidth
    >
      {props.loading && (
        <CircularProgress
          className={classes.spinner}
          color="primary"
          size={20}
        />
      )}
      {props.loading ? (
        <span className={classes.loadingText}>verzenden...</span>
      ) : (
        props.children
      )}
    </Button>
  )
}

LoadingButton.propTypes = {
  loading: PropTypes.bool.isRequired,
}

export default LoadingButton
