import React from "react"

const Acupunctuur = () => {
  return (
    <section>
      <h1>Acupunctuur</h1>

      <p>
        Acupunctuur is een onderdeel van de Traditionele Chinese Geneeswijze.
        Dit is een volledig medisch systeem dat al meer dan 3000 jaar diagnoses
        stelt, ziektes behandelt, klachten vermindert.
      </p>

      <p>
        In graftombes van duizenden jaren oud zijn naalden van steen, been en
        bamboe ontdekt en de in het ijs gevonden “ ijsman ” Otzi had tatoeages
        van acupunctuurpunten op zn lijf.
      </p>

      <p>
        Ook bestaan er duizendjaren oude teksten over tong- en polsdiagnose,
        kruiden en acupunctuur die nog steeds bestudeerd en opnieuw
        geïnterpreteerd worden.
      </p>

      <h2>Preventief gebruik van acupunctuur</h2>

      <p>
        Van oorsprong werd acupunctuur preventief ingezet. De dokter van de
        keizer werd ontslagen als de keizer ziek werd! Acupunctuur kan helpen
        ziektes te voorkomen.
      </p>

      <h2>Oost-West</h2>

      <p>
        De Chinese zienswijze benadert de mens en ziekte anders dan de Westerse
        geneeskunde. Ze verschilt hierin door niet de ziekte maar de zieke in
        diens omgeving centraal te stellen. Alles wat “is” heeft in de Chinese
        filosofie een samenhang en is constant in beweging. De mens bestaat uit
        een altijd aanwezige werveling van levensenergie/Qi. Ieder van ons vindt
        daarin een balans. Bij ziekte of pijn is het evenwicht verstoord. Een
        acupuncturist zal met behulp van naaldjes het lichaam stimuleren het
        zelfgenezende vermogen aan te wenden om het evenwicht te herstellen. Het
        is de bedoeling een goed functionerende balans te vinden en die is voor
        iedereen anders.
      </p>

      <h2>Fertiliteitsproblemen</h2>

      <p>
        In de praktijk hebben we m.b.t fertiliteit vaak goede resultaten door de
        combinatie van acupunctuur en kruiden. Veel onderzoeken tonen aan dat
        IVF een grotere slagingskans heeft met behulp van acupunctuur en
        kruiden. Er zijn steeds meer ziekenhuizen die acupunctuur tijdens
        bevallingen toestaan.
      </p>

      <h2>Ondersteuning chemo- en radiotherapie</h2>

      <p>
        Tijdens het ondergaan van chemo- en radiotherapie kan men veel baat
        hebben bij acupunctuur. De sessies met naalden kalmeren het systeem,
        helpen de organen de medicijnen te verwerken en patiënten zeggen dat ze
        zich daardoor krachtiger voelen en minder overgeleverd aan hun dokters
        en hun ziekte. Ook zijn er de laatste jaren kruidenformules ontwikkeld
        die goed aansluiten bij moderne medicijnen en deze ook ondersteunen. Zo
        helpen we mensen hun chemo of radiotherapie beter te verdragen, en hun
        conditie te verbeteren waardoor ze langer met de behandelingen door
        kunnen om resultaat te maximaliseren.
      </p>

      <h2>De behandeling</h2>

      <p>
        Tijdens een behandeling zal ik uitleggen wat ik doe. Ook als u niet
        snapt hoe acupunctuur werkt, of er niet in gelooft, doen de naaldjes hun
        werk. Vaak is na 1 tot 3 behandelingen verbetering merkbaar, bij
        chronische klachten zijn meerdere consulten nodig. Tijdens uw eerste
        bezoek vindt er een uitgebreid gesprek plaats over uw klachten, conditie
        en omstandigheden. Verder zal ik elke keer uw tong en pols bestuderen om
        mijn behandelstrategie te bepalen. Elk naaldje dat ik gebruik is steriel
        verpakt en wordt direct na de behandeling weggeworpen en op verantwoorde
        wijze vernietigd. Soms voelt men het insteken van een naaldje, maar over
        het algemeen is er geen sprake van pijn. Het is praktisch tijdens de
        behandeling loszittende kleding te dragen en vlak vantevoren geen koffie
        te drinken.
      </p>

      <p>
        Ik ben lid van de beroepsvereniging <a href="http://zhong.nl/">ZHONG</a>{" "}
        dus de meeste zorgverzekeraars vergoeden de acupunctuurconsulten of een
        deel daarvan mits u een aanvullend pakket heeft. Een verwijsbriefje van
        de huisarts is meestal niet nodig.
      </p>
    </section>
  )
}

export default Acupunctuur
