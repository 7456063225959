import React from "react"
import barbara from "../images/pictures/barabara.jpg"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
  },
}))

const About = () => {
  const classes = useStyles()

  return (
    <section>
      <h2>Over Barbara, acupuncturist in Amsterdam</h2>

      <p>Ik ben Barbara de Klein</p>

      <p>
        Als voormalig trapeze-werkster leerde ik als geen ander ongekende
        krachten te mobiliseren om zwakke punten te compenseren of juist te
        transformeren in mijn voordeel. Dat was goed voor de schone kunsten maar
        evenzovaak van levensbelang. Soms riep ik de hulp van een acupuncturist
        in en dat werkte zo goed dat ik besloot de opleiding Chinese geneeskunde
        te gaan volgen. Ik ben als acupuncturist afgestudeerd aan de TCMA
        (Traditional Chinese Medicine Amsterdam) en heb daarna een korte stage
        in China gedaan. Later heb ik de opleiding Chinese Kruiden geneeskunde
        bij TCM-postgraduate voltooid. Ik geef inmiddels zelf praktijklessen aan
        de TCMA en ben stagebegeleider.
      </p>

      <img className={classes.image} src={barbara} alt="Barbara de Klein" />

      <p>
        Sinds ik mijn studie heb afgerond behandel ik mensen met zeer
        uiteenlopende klachten van zowel fysieke als mentale aard. Ik doe dat
        middels acupunctuur en indien nodig/gewenst vul ik dit aan met Chinese
        kruiden of voedingssupplementen, een enkele keer met Qi Kung oefeningen.
      </p>
    </section>
  )
}

export default About
